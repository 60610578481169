<div *ngIf="footer">

  <section class="newsroom_bnr">
    <!-- <div class="artboardAnimation"></div>
    <div class="blackCircle_Animation"></div> -->
    <div class="container height_100per">

      <div>
        <div>
          <h2 class="textAlign_center font_Size32 font_Weight600  infiniteNightText">Newsroom</h2>
          <div class="margin_Bottom24 textAlign_center">
            <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/phaserBeamLinebottom.svg"
              alt="phaserBeamLinebottom" />
          </div>
          <!-- <p class="textAlign_center font_Size16 font_Weight500 steelWoolText ">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> -->
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="newsList.length" class="newsroom_wrapper">
    <div class="container">

      <div class="newsroomItems" *ngFor="let news of newsList">
        <div class="row">
          <div *ngIf="news?.page?.short_image" class="newsroomItem_img col-md-4">
            <img [src]="news?.page?.short_image" [alt]="news?.news_name" />
          </div>
          <div class="col-md-8">
            <h5 class="font_Size20 font_Weight500 infiniteNightText margin_Bottom12">{{ news?.news_name }}</h5>
            <p class="font_Size16 font_Weight400 steelWoolText margin_Bottom34">{{ news?.page?.short_content ?? "No
              Description" }} | <span
                class="phaserBeamText font_Size14 font_Weight600">{{formatDate(news.page.created_at)}}</span></p>

            <a (click)="navigate(news)" class="view_btn">
              <span>View</span>
              <img class="width_18px"
                src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/infiniteNightRight_arrow.svg"
                alt="infiniteNightRight_arrow" />
            </a>
          </div>
        </div>
        <!-- <div class="extraordinary_abundance_bottomborder padding_Bottom16 margin_Top24 margin_Bottom24"></div> -->
      </div>
      <div>
        <app-pagination [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" [currentPage]="currentPage"
          (pageChanged)="onPageChange($event)"></app-pagination>

      </div>
    </div>
  </section>


  <section *ngIf="blogList.length" class="newsroom_blog">
    <div class="container">

      <div class="DeskFlex_Mobileblock justify-content_Spacebetween align_item_Center margin_Bottom34">
        <div>
          <h2 class="font_Size32 infiniteNightText font_Weight700">Blog</h2>
          <div>
            <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/small_phaserBeamLinebottom.svg"
              alt="small_phaserBeamLinebottom">
          </div>

        </div>
        <div>
          <a (click)="bloglistNavigate()" class="Mainbutton infiniteNightBtn_clr">Show all</a>
        </div>
      </div>


      <div class="owl-carousel owl-theme blog_owl">
        <div *ngFor="let blog of blogList" class="newsroom_blog_Item">
          <div (click)="navBlogdetail(blog.slug)" class="newsroom_blog_img margin_Bottom16">
            <img [src]="blog?.page?.short_image || defaultIMG" [alt]="blog?.blog_name">
          </div>

          <h5 class="font_Size16 font_Weight600 infiniteNightText margin_Bottom12">{{ blog?.blog_name }}</h5>
          <p class="font_Size16 font_Weight400 steelWoolText margin_Bottom12" [innerHTML]="blog?.page?.short_content">
          </p>
        </div>
      </div>
    </div>
  </section>
</div>