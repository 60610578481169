<!-- pagination.component.html -->
<div class="DeskFlex_Mobileblock align_item_Center justify-content_Spacebetween">

  <div class="font_Size16 font_Weight500 steelWoolText MobSpace_mb24">{{ getDisplayRange() }}</div>

<div class="Display_Flex align_item_Center gap_12 flex_wrap_Wrap">
  <button (click)="prevPage()" class="prevPage">Previous</button>
  <ng-container *ngFor="let page of getPages()">
    <button class="paginationItem"
            (click)="setPage(page)"
      [ngClass]="{ 'active': currentPage === page }"
    >
      {{ page }}
    </button>
  </ng-container>
  <button (click)="nextPage()" class="nextPage">Next</button>
</div>
</div>
