<div *ngIf="footer">

  <section class="blogpage_bnr">
    <!-- <div class="artboardAnimation"></div> -->
    <!-- <div class="blackCircle_Animation"></div> -->
    <div class="container height_100per">

      <div>
        <div>
          <h2 class="textAlign_center font_Size32 font_Weight600  infiniteNightText">Blog</h2>
          <div class="margin_Bottom24 textAlign_center">
            <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/phaserBeamLinebottom.svg"
              alt="phaserBeamLinebottom" />
          </div>
          <!-- <p class="textAlign_center font_Size16 font_Weight500 steelWoolText ">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> -->
        </div>
      </div>
    </div>
  </section>


  <section class="newsroom_wrapper">

    <div class="container">
      <div class="newsroomItems" *ngFor="let blog of blogList">
        <div class="row">
          <div *ngIf="blog?.page?.short_image" class="newsroomItem_img col-md-4">
            <img [src]="blog?.page?.short_image " />
          </div>
          <div class="col-md-8">
            <h5 class="font_Size20 font_Weight500 infiniteNightText margin_Bottom12">{{ blog?.blog_name }}</h5>
            <p class="font_Size16 font_Weight400 steelWoolText margin_Bottom34">{{ blog?.page?.short_content ?? "No
              Description" }} | <span
                class="phaserBeamText font_Size14 font_Weight600">{{formatDate(blog.page.created_at)}}</span></p>

            <a (click)="navigate(blog.slug)" class="view_btn">
              <span>View</span>
              <img class="width_18px"
                src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/infiniteNightRight_arrow.svg"
                alt="infiniteNightRight_arrow" />
            </a>
          </div>
        </div>

        <!-- <div class="extraordinary_abundance_bottomborder padding_Bottom16 margin_Top24 margin_Bottom24"></div> -->
      </div>
      <div>
        <app-pagination [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" [currentPage]="currentPage"
          (pageChanged)="onPageChange($event)"></app-pagination>

      </div>
    </div>


  </section>

  <!-- Display your data based on pagination -->
  <section *ngIf="newsList?.length" class="newsroom_blog">
    <div class="container">

      <div class="Display_Flex justify-content_Spacebetween align_item_Center margin_Bottom34">
        <div>
          <h2 class="font_Size32 infiniteNightText font_Weight600">Newsroom</h2>
          <div>
            <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/small_phaserBeamLinebottom.svg"
              alt="small_phaserBeamLinebottom">
          </div>

        </div>
        <div>
          <a (click)="newslistNavigate()"><button class="Mainbutton infiniteNightBtn_clr">Show all</button></a>

        </div>
      </div>



      <div class="owl-carousel owl-theme blog_owl">
        <div *ngFor="let news of newsList" class="newsroom_blog_Item">
          <div (click)="navNewsDetail(news)" class="newsroom_blog_img margin_Bottom16">
            <img [src]="news?.page?.short_image || defaultIMG" [alt]="news?.news_name">
          </div>
          <div>
            <h5 class="font_Size16 font_Weight600 infiniteNightText margin_Bottom12">{{ news?.news_name }}</h5>
            <p class="font_Size16 font_Weight400 steelWoolText margin_Bottom12">{{news?.page?.short_content}} | <span
                class="phaserBeamText font_Weight600">{{formatDate(news.page.created_at)}}</span> </p>

          </div>

        </div>

      </div>
    </div>
  </section>
</div>