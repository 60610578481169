import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from '../../common_service/http.service';
import { Router } from '@angular/router';
import { ApiService } from '../../blog/shared/data-service.service';
import { SessionStorageService } from '../../session-service.service';
import { isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment'
import { response } from 'express';
declare var $: any;
@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent {
  newsList: any = []
  defaultIMG: string = environment.defaultImg;

  blogList: any = [];

  totalItems: number = 100; // Replace with your data
  itemsPerPage: number = 10;
  currentPage: number = 1;
  footer = false;
  constructor(

    public service: HttpService,
    private _router: Router,
    private apiService: ApiService,
    private sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    public _service: HttpService,
  ) {
    this._service.showFooter.subscribe((x) => {
      if (x != null) {
        this.footer = x;
      }
    });
  }



  ngOnInit() {
    this.titleService.setTitle('News Archives - PLI');
    let id = this.sessionStorageService.getItem("company_id");

    let menu = this.sessionStorageService.menu

    if (id && menu?.length) {
      this.getData()
    } else {
      this.apiService.getMenu()?.subscribe(
        (response) => {
          this.getData()
        }, (error) => {
          // Handle error if needed
          console.error('Error fetching menu', error);
        }
      );

    }
  }

  formatDate(dateString: Date) {
    if (!dateString) {
      return null
    }
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  navigate(news: any) {
    if (news?.category === 'BLOG') {
      this._router.navigate([`card-talk/${news?.slug}`])
    } else {
      this._router.navigate([`news/detail/${news?.news_id}`])
    }
  }

  bloglistNavigate() {
    this._router.navigate(['blog/list'])
  }

  navBlogdetail(slug: string) {

    this._router.navigate([`card-talk/${slug}`])
  }

  slider() {
    if (isPlatformBrowser(this.platformId)) {
      $('.blog_owl').owlCarousel({
        navText: ['<img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/left_arrow.svg">', '<img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/righarrow_icon.svg">'],
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        center: false,
        autoplay: true,
        autoplayHoverPause: true,
        autoplaySpeed: 5000,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 4
          }
        }
      })

      // counter number
      $('.counter_number').each(() => {
        $(this).prop('Counter', 0).animate({
          Counter: $(this).text()
        }, {
          duration: 5000,
          easing: 'swing',
          step: (now: any) => {
            $(this).text(Math.ceil(now));
          }
        });
      });


    }
  }
  onPageChange(newPage: number): void {
    // Handle the page change logic here

    let id = this.sessionStorageService.getItem("company_id");
    let page = ((newPage - 1) * 10);
    this.apiService.getAllNews(id, false, page, this.itemsPerPage).subscribe({
      next: (data: any) => {

        this.newsList = data?.news || [];
        this.totalItems = data?.count;
        window.scrollTo(0, 0);
      },
      error: (error) => {
        console.log("error", error)
      }
    });

  }



  getData() {
    let id = this.sessionStorageService.getItem("company_id");
    if (id) {
      this._service.hideAndShoeFooter(true);
      // clearInterval(getall);
      this.apiService.getAllNews(id).subscribe({
        next: (data: any) => {
          this.newsList = data?.news || [];
          this.totalItems = data?.count;
        },
        error: (error) => {
          console.log("error", error)
        }
      });
      this.apiService.getAllBlogs(id, true).subscribe({
        next: (data: any) => {
          ;
          this.blogList = data?.blogs || [];
          setTimeout(() => { this.slider() }, 100)

        },
        error: (error) => {
          console.log("error", error)
        }
      });
    }
  }

}
