import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpService } from './http.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
  private requestCount = 0;

  constructor(private spinner: NgxSpinnerService, private service: HttpService, private route: ActivatedRoute,) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.requestCount++;
    this.spinner.show();
    const url = new URL(request.urlWithParams);
    const queryParams:any = {};

    

    url.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

  
    const shouldLoadService = !(queryParams['loader'] === 'false');

    if (!shouldLoadService) {
      // If 'loader' is set to false, do not proceed with the request
      this.requestCount--;
      this.spinner.hide();
      return next.handle(request);
    }
    return next.handle(request).pipe(
      
      tap({
  
        next: (event) => {
          if (event instanceof HttpResponse) {
            this.requestCount--;
            if (this.requestCount < 1) {
              this.spinner.hide();
            }
          }
        },
        error: (error) => {
          this.requestCount--;
          if (this.requestCount < 1) {
            this.spinner.hide();
          }
        },
        complete: () => {
          // this.requestCount--;
          if (this.requestCount < 1) {
            this.spinner.hide();
          }
        },
      })
    );
  }
}
