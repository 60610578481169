import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-optin-form',
  templateUrl: './optin-form.component.html',
  styleUrls: ['./optin-form.component.scss']
})
export class OptinFormComponent {
  optinForm: any = {};

  constructor(protected sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<OptinFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.optinForm = data.formData;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  safeHTML(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
