<div *ngIf="footer">
  <section class="newsroom_wrapper">
    <div class="container">
      <div class="row">

        <div class="col-md-8">



          <div class="">


            <div (click)="navigate()" class="Display_Flex gap_12 align_item_Center">
              <div>
                <a>
                  <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/back_arrow_icon.svg"
                    class="width_10px" alt="back_arrow_icon" />
                </a>

              </div>
              <div>
                <span class="infiniteNightText font_Size32 font_Weight600">Blog</span>


              </div>

            </div>

            <div class="margin_Bottom34">
              <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/small_phaserBeamLinebottom.svg"
                [alt]="blogDetail?.blog_name ?? 'No Image' " />

            </div>

            <!-- <div *ngIf="blogDetail?.page?.short_image" class="newsroomOverview_img margin_Bottom16">
              <img [src]="blogDetail?.page?.short_image" alt="yello_card" />
            </div> -->

            <div
              class="Display_Flex align_item_Center justify-content_Spacebetween margin_Bottom16 padding_Bottom12 extraordinary_abundance_bottomborder">
              <div>
                <a class="Display_Flex align_item_Center gap_12">
                  <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/calendar_icon.svg"
                    alt="calendar_icon" />
                  <span class="phaserBeamText font_Size14 font_Weight600">{{ formatDate(blogDetail?.page?.created_at)
                    }}</span>
                </a>

              </div>
              <div>

                <ul class="graySocial_icon">
                  <li>
                    <a target="_blank" href="{{ fb_url }}" class="Display_Flex align_item_Center gap_12">
                      <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/gray_fb.svg"
                        alt="gray_fb" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="{{ twitter_url }}" class="Display_Flex align_item_Center gap_12">
                      <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/gray_x.svg"
                        alt="gray_x" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="{{ mail_url }}" class="Display_Flex align_item_Center gap_12">
                      <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/gray_email.svg"
                        alt="gray_email" />
                    </a>
                  </li>

                  <li>
                    <a target="_blank" href="{{ linked_url }}" class="Display_Flex align_item_Center gap_12">
                      <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/gray_linkedin.svg"
                        alt="gray_linkedin" />
                    </a>
                  </li>
                </ul>


              </div>
            </div>

            <h5 class="font_Size24 font_Weight500 infiniteNightText margin_Bottom12">{{blogDetail?.blog_name ?? ''}}
            </h5>
            <div class="blogContentFrame">
              <div [innerHTML]=" blogContent | sanitizer"></div>

            </div>
            <p class="font_Size16 font_Weight400 steelWoolText margin_Bottom12"> </p>


          </div>


        </div>

        <div class="col-md-4">
          <div *ngIf="recentBlogList?.length">
            <h2 class="infiniteNightText font_Size32 font_Weight600">Recent</h2>
            <div class="margin_Bottom34">
              <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/small_phaserBeamLinebottom.svg"
                alt="small_phaserBeamLinebottom" />

            </div>
            <div>

              <div *ngFor="let blog of recentBlogList">
                <div class="recent_news ">
                  <h3 (click)="navDetail(blog.slug)"
                    class="  font_Size16 font_Weight500 infiniteNightText margin_Bottom12 cursor_Pointer">
                    {{blog?.page?.short_content}}
                  </h3>

                  <h4 class="font_Size14 steelWoolText font_Weight500 margin_Bottom16">
                    {{formatDate(blog?.page?.created_at)}}</h4>
                </div>
                <div class="extraordinary_abundance_bottomborder margin_Bottom16"></div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
  <section *ngIf="newsList?.length" class="newsroom_blog">
    <div class="container">

      <div class="DeskFlex_Mobileblock justify-content_Spacebetween align_item_Center margin_Bottom34">
        <div>
          <h2 class="font_Size32 infiniteNightText font_Weight700">Newsroom</h2>
          <div>
            <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/small_phaserBeamLinebottom.svg"
              alt="small_phaserBeamLinebottom">
          </div>

        </div>
        <div>
          <button (click)="navNewslist()" class="Mainbutton infiniteNightBtn_clr">Show all</button>
        </div>
      </div>


      <div class="owl-carousel owl-theme blog_owl">
        <div *ngFor="let news of newsList" class="newsroom_blog_Item">
          <div (click)="navNewsDetail(news)" class="newsroom_blog_img margin_Bottom16 cursor_Pointer">
            <img [src]="news?.page?.short_image || defaultIMG" [alt]="news?.news_name">
          </div>
          <div>
            <h5 class="font_Size16 font_Weight600 infiniteNightText margin_Bottom12">{{ news?.news_name }}</h5>
            <p class="font_Size16 font_Weight400 steelWoolText margin_Bottom12">{{news?.page?.short_content}} | <span
                class="phaserBeamText font_Weight600">{{formatDate(news.page.created_at)}}</span> </p>

          </div>

        </div>

      </div>
    </div>
  </section>

</div>