import { Injectable,PLATFORM_ID,Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {

  menu: any = [];
  footer: boolean = true;
constructor(    @Inject(PLATFORM_ID) private platformId: Object,){

}

  setItem(key: string, value: string): void {
    
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem(key, value);
    }

  }

  getItem(key: string):any {
    if (isPlatformBrowser(this.platformId)) {
      return sessionStorage.getItem(key);
    }
   
  }

  removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem(key);
    }
   
  }

  clear(): void {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.clear();
    }
  
  }

setMenu(value:any){
  
  this.menu = value ;
}

}
