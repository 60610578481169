<div *ngIf="footer && menu.length && isBrowser" class="sticky-top">
  <div class="topHeader">
    <div class="container">
      <div>
        <div class="DeskFlex_Mobileblock align_item_Center gap_30 MobSpace_mb12">
          <div class="MobSpace_mb12">
            <p class="whiteColor_Text font_Size14 font_Weight500">USA & Canada: <a class="hrefLinkHover whiteColor_Text"
                href="tel:+1-800-752-1017"> +1-800-752-1017</a>
            </p>
          </div>
          <div>
            <p class="whiteColor_Text font_Size14 font_Weight500">International : <a
                class="hrefLinkHover whiteColor_Text" href="tel:+1 828-210-4754"> +1 828-210-4754</a>
            </p>
          </div>
        </div>
        <div>
          <ul class="topHeaderMenulist">
            <li><a class="cursor_Pointer" (click)="navTo('blog/list')">Blog</a></li>
            <li><a href="./submit_artwork">Submit Artwork</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="navbar-area navbar-expand-lg siteHeader_wrapper">

    <div class="container">
      <nav class="navbar">

        <div class="Display_Flex align_item_Center justify-content_Spacebetween mobileHeader">
                    <div class="MobileToggle">
                      <button onclick="toggleClass()" class="noColor_btn">
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>
                    </div>
          <div>
            <a class="pli_brandLogo" href="/">
              <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/pli_logo.svg" alt="pli_logo">
            </a>
          </div>
          <div class="mobileSearch">
                          <div>
                            <button *ngIf="!search" type="button" class="noColor_btn">
                              <img (click)="searchHandle()" class="width_24"
                                src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/search_icon.svg" alt="search_icon" />
                            </button>
                            <!-- closeSearch/showSearch -->
                            <div id="search" [ngClass]="search ? 'searchInputGrp showSearch':'searchInputGrp closeSearch'"><span
                                class="searchInputGrptext cursor_pointer">
                                <img style="outline: none;" *ngIf="search" class="width_24"
                                  src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/search_icon.svg" alt="search_icon" />
                              </span>
                              <input placeholder="Search ..." style="outline: none;" (input)="onSearchInput($event)"
                                (keydown.enter)="onEnterKeyPress($event)" id="search-input">
                              <button *ngIf="search" type="button" class="noColor_btn closeIcon">
                                <img style="outline: none;" id="searchCloseButton" (click)="searchHandle()" class="width_24"
                                  src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/circle_closeIcon.svg">
                              </button>
                            </div>




                          </div>
                          <div class="searchResults" *ngIf="search && searchResults.length >0">

                            <div class='SingleSearchDiv' *ngFor="let result of searchResults">
                              <span (click)="goToSearch(result)"> {{ result?.title }}</span>
                            </div>

                          </div>
                          <div class="searchResults Display_Flex align_item_Center justify_content_Center"
                            *ngIf="search &&(currentSearchTerm && currentSearchTerm !='' && searchResults.length ==0)">
                            <h5 class="steelWoolText font_Size16 font_Weight500  textAlign_center">No results found. </h5>
                          </div>
          </div>
        </div>

        <div id="toggleButton" class="mobileMenu" aria-expanded="fa">

          <div id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" (click)="homeNavigation('')">
                  <img
                    src="https://theecode-assets.s3.us-west-1.amazonaws.com/Crm-Image/default-image/theecode-pli-imgs/home_icon.svg"
                    alt="home_icon" />
                </a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link" (click)="navTo('news/list')"> Newsroom</a>
              </li>

              <li class="nav-item dropdown" *ngFor="let items of menu">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink_one" data-toggle="dropdown"
                  *ngIf="items.children.length !=0" aria-haspopup="true" aria-expanded="false">{{ items.menu_name }}</a>
                <a class="nav-link" (click)="navTo(items.slug)"
                  *ngIf="items.children.length ==0 && items.menu_name !=='Sustainability'  && items.menu_name !='Home'">{{
                  items.menu_name
                  }}</a>
                <a *ngIf="items.menu_name=='Sustainability'" class="nav-link Sustanability_text"
                  (click)="navTo(items.slug)">Susta<span class="leaf_icon">I</span>nability</a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink_one"
                  *ngIf="items.children.length !=0">
                  <li *ngFor="let child of items.children">
                    <a class="dropdown-item" (click)="navTo(child.slug)">
                      <img
                        src="https://theecode-assets.s3.us-west-1.amazonaws.com/Crm-Image/default-image/theecode-pli-imgs/list_arrow.svg"
                        alt="list_arrow" />
                      <Span>{{ child.menu_name }}</Span>
                    </a>

                </ul>
              </li>
              <li class="nav-item paddingLeftright_12">
                <div>
                  <button *ngIf="!search" type="button" class="noColor_btn">
                    <img (click)="searchHandle()" class="width_24"
                      src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/search_icon.svg"
                      alt="search_icon" />
                  </button>
                  <!-- closeSearch/showSearch -->
                  <div id="search" [ngClass]="search ? 'searchInputGrp showSearch':'searchInputGrp closeSearch'"><span
                      class="searchInputGrptext cursor_pointer">
                      <img style="outline: none;" *ngIf="search" class="width_24"
                        src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/search_icon.svg"
                        alt="search_icon" />
                    </span>
                    <input placeholder="Search ..." style="outline: none;" (input)="onSearchInput($event)"
                      (keydown.enter)="onEnterKeyPress($event)" id="search-input">
                    <button *ngIf="search" type="button" class="noColor_btn closeIcon">
                      <img style="outline: none;" id="searchCloseButton" (click)="searchHandle()" class="width_24"
                        src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/circle_closeIcon.svg">
                    </button>
                  </div>




                </div>
                <div class="searchResults" *ngIf="search && searchResults.length >0">

                  <div class='SingleSearchDiv' *ngFor="let result of searchResults">
                    <span (click)="goToSearch(result)"> {{ result?.title }}</span>
                  </div>

                </div>
                <div class="searchResults Display_Flex align_item_Center justify_content_Center"
                  *ngIf="search &&(currentSearchTerm && currentSearchTerm !='' && searchResults.length ==0)">
                  <h5 class="steelWoolText font_Size16 font_Weight500  textAlign_center">No results found. </h5>
                </div>
                <!-- onclick="handleSearch()" onclick="handleSearch()" -->


              </li>


              <!-- <div class="searchResults">

  </div>
               -->
            </ul>
          </div>

        </div>
      </nav>
    </div>
  </div>

</div>
