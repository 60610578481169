// pagination.component.ts

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent   {
  @Input() totalItems: number = 0;
  @Input() itemsPerPage: number = 10;
  @Input() currentPage: number = 1;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['currentPage']) {
  //     this.handlePageChange();
  //   }
  // }

  private handlePageChange(): void {
    this.pageChanged.emit(this.currentPage);
  }

  get numPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  getPages(): number[] {
    const totalPages = this.numPages;
    const visiblePages = Math.min(totalPages, 10);
    return Array.from({ length: visiblePages }, (_, i) => i + 1);
  }

  setPage(page: number): void {
    if (typeof page === 'number' && page >= 1 && page <= this.numPages && page !== this.currentPage) {
      this.currentPage = page;
      this.pageChanged.emit(this.currentPage);
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.setPage(this.currentPage - 1);
    }
  }

  nextPage(): void {
    if (this.currentPage < this.numPages) {
      this.setPage(this.currentPage + 1);
    }
  }

  getStartIndex(): number {
    return (this.currentPage - 1) * this.itemsPerPage + 1;
  }

  getEndIndex(): number {
    const end = this.currentPage * this.itemsPerPage;
    return end > this.totalItems ? this.totalItems : end;
  }

  getDisplayRange(): string {
    return `${this.getStartIndex()}-${this.getEndIndex()} of ${this.totalItems}`;
  }
}
