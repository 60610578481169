import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  sharedVariable: any;
  public showFooterObject: BehaviorSubject<any>;
  public showFooter: Observable<boolean>;
  uiUrl = environment.Site_url;
  constructor(
    private http: HttpClient, 
    private device: DeviceDetectorService, 
    private meta : Meta,
    private router: Router) {
    this.showFooterObject = new BehaviorSubject<any>(false);
    this.showFooter = this.showFooterObject.asObservable();
  }

  get(url: string) {
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')

    let options = {
      headers: httpHeaders,
    };
    return this.http.get(url, options);
  }
  hideAndShoeFooter(val: any) {
    this.showFooterObject.next(val);
}
  post(url: string, data: any) {
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Pragma', 'no-cache')

    let options = {
      headers: httpHeaders,
    };

    return this.http.post(url, data, options);
  }

  formPost(url: string, data: any) {
    return this.http.post<any>(url, data);
  }


  put(url: string, data: any) {
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Pragma', 'no-cache')

    let options = {
      headers: httpHeaders,
    };

    return this.http.put(url, data, options);
  }


  formPut(url: string, data: any) {
    return this.http.put(url, data);
  }

  patch(url: string, data: any) {
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')

    let options = {
      headers: httpHeaders,
    };
    return this.http.patch(url, data, options);
  }

  delete(url: string, params: any) {
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')

    let options = {
      headers: httpHeaders,
      body: params
    };
    return this.http.delete(url, options);
  }

  isDesktopDevice() {   //  Device Detection
    return this.device.isDesktop();
  }

  logout() {
    sessionStorage.clear();
    location.reload();
  }

  clearCoGetApi(url: string) {
    const base64Credentials = btoa(`${environment.ClearCo_UserName}:${environment.ClearCo_Password}`);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64Credentials,
        'API-Realm': 'CCAPI'
      })
    };
    return this.http.get(url, httpOptions);
  }

  updateDescription(content: any) {
    this.meta.updateTag({ name: 'description', content });
  }
  //Facebook
  updateOgUrl() {
    let url = this.uiUrl + this.router.url;
    url = url.replace(".com//", ".com/");

    this.meta.updateTag({ name: 'og:url', property: 'og:url', content: url });
  }

  updateOgTitle(content: any) {
    this.meta.updateTag({ name: 'og:title', property: 'og:title', content });
  }

  updateOgDescription(content: any) {
    this.meta.updateTag({ name: 'og:description', property: 'og:description', content });
  }

  updateOgImage(content: any, image: boolean) {
    if(image) {
      this.meta.updateTag({ name: 'og:image', property: 'og:image', content });
    }
    else {
      let metaArr = content?.page?.page_data?.split(`\"`);
      // console.log('metaArr::: ', metaArr);
  
      let metaImg = this.fetchImage(metaArr);
  
      this.meta.updateTag({ name: 'og:image', property: 'og:image', content: metaImg });
    }
  }

  updateTwitterCard(content: any) {
    this.meta.updateTag({ name: 'twitter:card', property: 'twitter:card', content });
  }

  updateTwitterUrl() {
    let url = this.uiUrl + this.router.url;
    url = url.replace(".com//", ".com/");

    this.meta.updateTag({ name: 'twitter:url', property: 'twitter:url', content: url });
  }

  updateTwitterTitle(content: any) {
    this.meta.updateTag({ name: 'twitter:title', property: 'twitter:title', content });
  }

  updateTwitterDescription(content: any) {
    this.meta.updateTag({ name: 'twitter:description', property: 'twitter:description', content });
  }

  updateTwitterImage(content: any, image: boolean) {
    if(image) {
      this.meta.updateTag({ name: 'twitter:image', property: 'twitter:image', content });
    }
    else {
      let metaArr = content?.page?.page_data?.split(`\"`);
      // console.log('metaArr::: ', metaArr);

      let metaImg = this.fetchImage(metaArr);
  
      this.meta.updateTag({ name: 'twitter:image', property: 'twitter:image', content: metaImg });
    }
  }

  fetchImage(html : any) {
    // console.log('html::: ', html);
    let metaImgInd = html?.indexOf(` src=\\`);
    // console.log('metaImgInd::: ', metaImgInd);
    let metaImg = html[metaImgInd + 1].split("\\")[0];
    // console.log('metaImg::: ', metaImg);

    return metaImg;
  }

}
