import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,  of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from '../../session-service.service';
import { catchError, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl = environment.WebsiteApiUrl;
  private domain = 'theecodecrm';



  companyId: number = 0;
  // emailUrl = environment.EmailAPIUrl;

  constructor(private http: HttpClient, private _router: Router, private sessionStorageService: SessionStorageService) { }


  getMenu(): Observable<any> {
    // if (this.sessionStorageService?.menu?.length) {
    //   return of(null);
    // }

    const api = `${this.apiUrl}/api/Website/get?Domain=${this.domain}&LoadCompanyInfo=true`;

    return this.http.get(api).pipe(
      tap((el: any) => {
        this.sessionStorageService.setItem('loginDetail', JSON.stringify(el));
        this.sessionStorageService.setItem('website_id', JSON.stringify(el?.website_id));
        this.sessionStorageService.setItem('company_id', JSON.stringify(el?.company_id));
        this.sessionStorageService.setMenu(el.menu);
      }),
      catchError((error) => {
        console.error('Error fetching data', error);
        return of(null);
      })
    );
  }


  getAllBlogs(CompanyId: number, recent: boolean = false, start: Number = 0, count: Number = 10): Observable<any> {
    if (recent) {
      return this.http.get(`${this.apiUrl}/api/Blog?companyId=${CompanyId}&start=${start}&count=${8}`);
    } else {
      return this.http.get(`${this.apiUrl}/api/Blog?companyId=${CompanyId}&start=${start}&count=${count}`);
    }

  }

  getAllBlogsHome(CompanyId: number): Observable<any> {

    return this.http.get(`${this.apiUrl}/api/Blog?companyId=${CompanyId}&start=${0}&count=${8}&loader=false`);


  }

  getBlogDetail(Slug: number, companyId: number): Observable<any> {

    return this.http.get(`${this.apiUrl}/api/Blog/blogbyslug?CompanyId=${companyId}&Slug=${Slug}&Draft=false`);
  }

  setBlogId(slug: string) {


    this._router.navigate([`card-talk/${slug}`]);


  }


  getAllNews(CompanyId: number, recent: boolean = false, start: Number = 0, count: Number = 10): Observable<any> {
    if (recent) {
      return this.http.get(`${this.apiUrl}/api/News?companyId=${CompanyId}&start=${start}&count=${8}&showNewsBlog=true`);
    } else {
      return this.http.get(`${this.apiUrl}/api/News?companyId=${CompanyId}&start=${start}&count=${count}&showNewsBlog=true`);
    }

  }

  getAllNewsHome(CompanyId: number): Observable<any> {

    return this.http.get(`${this.apiUrl}/api/News?companyId=${CompanyId}&start=${0}&count=${8}&showNewsBlog=true&loader=false`);


  }

  getNewsDetail(newsId: number, companyId: number): Observable<any> {

    return this.http.get(`${this.apiUrl}/api/News/Newsbyid?CompanyId=${companyId}&newsId=${newsId}&Draft=false`);
  }

  // serach detail

  getSearchDetail(companyId: Number, search: string, count: Number = 50): Observable<any> {

    return this.http.get(`${this.apiUrl}/api/Website/SearchResult?CompanyId=${companyId}&SearchFor=${search}`);
    // &ResultCount=${count}
  }

}
