import { Component, OnInit, Inject, PLATFORM_ID, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../blog/shared/data-service.service';
import { SessionStorageService } from '../session-service.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { HttpService } from '../common_service/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  // Define properties to store query parameter values
  param1Value: string = '';
  websiteList: any = [];
  blogList: any = [];
  private searchTerms = new Subject<string>();
  public currentSearchTerm: string = '';
  emailUrl = environment.EmailAPIUrl;
  websiteUrl = environment.WebsiteApiUrl;
  searchResults: any = [];
  slug: any = '';
  menu: any = [];
  search = false;
  thanksSlugs = ["contact-us/thank-you", "submit-artwork/thank-you", "subscription/thank-you"]


  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    public _service: HttpService,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {

    this.searchTerms.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(term => {
      this.currentSearchTerm = term;
      this.onSearch(term);
    });

    // Access query parameters
    this.route.queryParams.subscribe((params) => {
      // Assign query parameter values to component properties
      this.param1Value = params['search'];
      let id = this.sessionStorageService.getItem("company_id");
      if (id) {
        this.handleQueryParams(id);
      }

    });
    this.apiService.getMenu();

    let getall = setInterval(() => {
      let id = this.sessionStorageService.getItem("company_id");
      if (id) {
        this.handleQueryParams(id)
        clearInterval(getall);

      }
    }, 10)


  }


  onSearchInput(event: any): void {
    const searchTerm = event.target.value;
    this.searchTerms.next(searchTerm);
  }

  onSearch(searchTerm: any) {

    let id = this.sessionStorageService.getItem('company_id') ?? environment.companyId
    // console.log('id::: ', id);
    // const inputValue = this.input.nativeElement.value.trim();
    if (searchTerm !== '') {
      // Add your API call logic here
      let api = `${this.websiteUrl}/api/Website/search?CompanyId=${environment.companyId}&SearchFor=${searchTerm}`;
      this._service.get(api).subscribe((el: any) => {
        this.searchResults = el.filter((e: any) => !(this.thanksSlugs.includes(e?.slug)))
      })
    } else {
      this.searchResults = []
    }



  }

  // Example method to handle query parameters
  private handleQueryParams(companyID: Number): void {


    this.apiService?.getSearchDetail(companyID, this.param1Value).subscribe({
      next: (data: any) => {

        this.websiteList = data?.filter((e: any) => e?.category === 'WEBSITE' && !(this.thanksSlugs.includes(e?.slug)));
        this.blogList = data?.filter((e: any) => e?.category !== 'WEBSITE' && !(this.thanksSlugs.includes(e?.slug)));

      },
      error: (error) => {
        console.log("error", error)
      }
    });

    // Add your logic here...
  }


  nav(key: string) {
    this.router?.navigate([key])
  }
  formatDate(dateString: Date) {
    if (!dateString) {
      return null
    }
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  navBlog(type: string, data: any) {
    if (type === 'BLOG') {
      this.router?.navigate([`card-talk/${data?.slug}`])
    } else {
      this.router?.navigate([`news/detail/${data?.id}`])
    }
  }

  goToSearch(item: any) {

    if (item?.category == 'WEBSITE') {
      this.navTo(item.slug)
    } else if (item?.category === 'BLOG') {
      this.navTo(`card-talk/${item?.slug}`)
    } else if (item?.category === 'NEWS') {
      this.navTo(`news/detail/${item?.id}`)
    }

    this.searchHandle()
    this.currentSearchTerm = ''

    this.searchResults = []

  }

  searchHandle() {
    this.search = !this.search; const inputElement = this.elementRef.nativeElement.querySelector('#search-input');

    if (inputElement) {
      inputElement.value = '';
    }
    this.currentSearchTerm = '';
    this.searchResults = [];
  }

  navTo(route: any) {

    route = route.split('~');
    window.scrollTo(0, 0);
    this.router.navigate([`/${route[0]}`]);
    this.slug = ''
  }

}
