import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtInterceptor } from './common_service/jwt.interceptor';
import { DynamicPageComponent } from './dynamic-page/dynamic-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { OptinFormComponent } from './optin-form/optin-form.component';
import { SanitizerPipe } from 'src/sanitizer.pipe';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BlogListComponent } from './blog/blog-list/blog-list.component';
import { BlogDetailComponent } from './blog/blog-detail/blog-detail.component';
import { NewsListComponent } from './news/news-list/news-list.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import {PaginationComponent} from './common-component/pagination/pagination.component';
import {SearchComponent} from './search/search.component'
@NgModule({
  declarations: [
    AppComponent,
    DynamicPageComponent,
    OptinFormComponent,
    SanitizerPipe,
    BlogListComponent,
    BlogDetailComponent,
    NewsListComponent,
    NewsDetailComponent,
    HeaderComponent,
    PaginationComponent,
    SearchComponent
  ],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    CarouselModule,
    ReactiveFormsModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, DynamicPageComponent],
  bootstrap: [AppComponent],

})
export class AppModule { }
