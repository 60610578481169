export const environment = {
  production: false,
  SSRPORT: "4000",
  nodeAPIUrl: "http://54.241.72.188:3000",
  APIUrl: "https://pli-admin-api.sayshop.co",
  SendGridAPIUrl: "https://pli-social-api.sayshop.co",
  WebsiteApiUrl: "https://pli-web-api.sayshop.co",
  EmailAPIUrl: "https://pli-email-api.sayshop.co",
  SocialApiUrl: "http://54.241.72.188:7002", // Dev
  Site_url: "https://plicards.com/",
  Org_id: '00D5f000003J4rY',
  RetURL: 'https://www.plicards.com/contact-us/thank-you',
  salesForceURL: 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D5f000003J4rY',
  isLocalEnv: false,

  //ClearCo Credentials
  ClearCo_UserName: 'devpli@theecode.xyz',
  ClearCo_Password: '*4p+2en3EE',

  // default img
  defaultImg: 'https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/dummy-image.jpg',

  // CLIPBOARD URL
  ClipboardUrl: 'https://www.plicards.com/careers',

  //PDF URL
  sustainability_Pdf: "https://plicards.s3.us-west-1.amazonaws.com/pli-pdf-assets/sustainability.pdf",
  careers_Pdf: "https://plicards.s3.us-west-1.amazonaws.com/pli-pdf-assets/PLI_Benefits.pdf",
  submitArtwork_Pdf: "https://plicards.s3.us-west-1.amazonaws.com/pli-pdf-assets/Art_Specifications.pdf",
  privacy_policy: "https://plicards.s3.us-west-1.amazonaws.com/pli-pdf-assets/PLI_Biometric_Information_Privacy_Policy_Final_110619.pdf",

  emailPattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$",
  fontSize: 75,
  fontFamily: [{ displayName: "serif", value: "serif" }, { displayName: "MoonTime-Regular", value: "MoonTime-Regular" }, { displayName: "cursive", value: "cursive" }, { displayName: "Verdana", value: "Verdana" }],
  fontStyleList: [{ displayName: "regular", value: "regular" }, { displayName: "italic", value: "italic" }, { displayName: "bold", value: "bold" }, { displayName: "bold italic", value: "bold italic" }, { displayName: "underline", value: "underline" }],
  fontLineHeight: 30,
  order: [{ number: 0, value: 0 }, { number: 1, value: 1 }, { number: 2, value: 2 }, { number: 3, value: 3 }],
  fbAppId: '738993143334602',
  linkedinLoginUrl: "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86l5270scfeote&redirect_uri=http://localhost:4200/&state=987654321&scope=w_member_social+r_liteprofile+r_emailaddress",
  instagramLoginUrl: "https://api.instagram.com/oauth/authorize?client_id=312505673322106&redirect_uri=https://localhost:4200&scope=user_profile,user_media&response_type=code",
  froalaEditorKey: "iTB2xC2C4D4A1A1B1wd1DBKSPF1WKTUCQOa1OURPJ1KDe2F-11D2C2D2H2C3A3B2D6C1C2==",
  domainName: "sayshop+.com",
  companyId: 88
};

