<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-triangle-path"
  [fullScreen]="true"></ngx-spinner>





<div class="maincontent_wrapper">
  <app-header />
  <router-outlet></router-outlet>

  <div class="siteFooter_wrapper" *ngIf="footer && isBrowser">
    <div class="container">
      <div class="row margin_Bottom50">

        <div class="col-lg-4 MobSpace_mb24">
          <h2 class="font_Size20 font_Weight600 phaserBeamText margin_Bottom24">Headquarters Location</h2>

          <a target="_blank" href="https://maps.google.com/?q=1030+East+Craig+Road%2C+North+Las+Vegas%2C+NV%2C+USA"
            class="font_Size16 font_Weight400 whiteColor_Text Display_Block margin_Bottom16 hrefLinkHover">1030 E.
            Craig Road
            N. Las Vegas, NV 89030</a>

          <a href="https://maps.google.com/?q=1030+East+Craig+Road%2C+North+Las+Vegas%2C+NV%2C+USA" target="_blank"
            class="font_Size16 font_Weight500 whiteColor_Text margin_Bottom12 textDecoration_Underline Display_Block hrefLinkHover">Get
            Directions </a>

          <p class="font_Size16 font_Weight400 whiteColor_Text margin_Bottom4">USA & Canada: <a
              class="whiteColor_Text hrefLinkHover" href="tel:+1-800-752-1017">1-800-752-1017</a></p>
          <p class="font_Size16 font_Weight400 whiteColor_Text margin_Bottom34">International: <a
              class="whiteColor_Text hrefLinkHover" href="tel:+1 828-210-4754">+1 828-210-4754</a> </p>

          <ul class="socialMedia_icon">
            <li>
              <a href="https://www.facebook.com/plicards/" target="_blank">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/facebook_icon.svg"
                  alt="facebook_icon" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/pli_cards/" target="_blank">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/insta_icon.svg"
                  alt="insta_icon" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/pli-card-marketing-solutions/" target="_blank">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/linkedin_icon.svg"
                  alt="linkedin_icon" />
              </a>
            </li>

            <li>
              <a href="https://www.youtube.com/channel/UCKCll8OLoyjVmpJqNlenD-w" target="_blank">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/youtube_icon.svg"
                  alt="youtube_icon" />
              </a>
            </li>
          </ul>

        </div>

        <div class="col-lg-3 MobSpace_mb24">
          <h2 class="font_Size20 font_Weight600 whiteColor_Text margin_Bottom24">Quick Links</h2>

          <ul class="siteFooterMenulist">
            <li> <a href="pli-advantage" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>About Us</span>
              </a></li>
            <li> <a href="pli-advantage/leadership-team" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span> Leadership Team</span>
              </a></li>
            <li> <a href="industries/gift-loyalty" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Gift & Loyalty</span>
              </a></li>
            <li> <a href="markets/hospitality/international-hotels" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Hospitality</span>
              </a></li>
            <li> <a href="markets/events/event-key-cards" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Events</span>
              </a></li>
            <li> <a href="capabilities/card-printing-attributes" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Card Printing & Attributes</span>
              </a></li>
            <li> <a href="submit_artwork" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Submit Artwork</span>
              </a></li>
            <li> <a href="https://www.payerexpress.com/ebp/PLIQP/Login/Index" target="_blank"
                class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Bill Pay</span>
              </a></li>
          </ul>
        </div>
        <div class="col-lg-3 MobSpace_mb24">
          <h2 class="font_Size20 font_Weight600 whiteColor_Text margin_Bottom24">Resources</h2>

          <ul class="siteFooterMenulist">
            <li> <a href="fraud-prevention" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Fraud Prevention</span>
              </a></li>
            <li> <a href="sustainability" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Sustainability</span>
              </a></li>
            <li> <a (click)="navList('news')" class="cursor_Pointer font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Newsroom</span>
              </a></li>


            <li> <a href="careers" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span> Careers</span>
              </a></li>
            <li> <a (click)="navList('blog')" class="cursor_Pointer font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Blog</span>
              </a></li>
            <li> <a href="contact_us" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Contact Us</span>
              </a></li>
            <li> <a href="pli-advantage/locations" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Locations</span>
              </a></li>
          </ul>
        </div>
        <div class="col-lg-2">
          <h2 class="font_Size20 font_Weight600 whiteColor_Text margin_Bottom24">Other Links</h2>

          <ul class="siteFooterMenulist">
            <li> <a href="term_of_use" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Terms of Use</span>
              </a></li>
            <li> <a href="privacy_policy" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Privacy Policy</span>
              </a></li>

            <li> <a href="accessibility" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Accessibility</span>
              </a></li>

            <li> <a href="/sitemap" class="font_Size16 font_Weight400 whiteColor_Text">
                <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/list_arrow.svg"
                  alt="list_arrow" />
                <span>Sitemap</span>
              </a></li>
          </ul>
        </div>
      </div>

      <div class="DeskFlex_Mobileblock align_item_Center justify-content_Spacebetween">
        <div class="siteFooterLogo MobSpace_mb24">
          <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/pli_logowhite.svg"
            alt="pli_logowhite" />
        </div>
        <div>
          <p class="font_Size14 font_Weight400 whiteColor_Text"> Copyright © and ™ Plasticard-Locktech International.
            All Rights Reserved.</p>
        </div>
      </div>

    </div>
  </div>

  <div class="progress-wrap">
    <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
</div>