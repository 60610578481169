import { Component, PLATFORM_ID,Inject } from '@angular/core';
import { DynamicPageComponent } from './dynamic-page/dynamic-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import {SessionStorageService} from './session-service.service'
import {HttpService} from './common_service/http.service'
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Client-UI';

  footer = false;
  isBrowser : Boolean = false;
  isServer : Boolean = false;
  constructor(
    // public DynamicPageComponent:DynamicPageComponent,
    private route: Router,
    private sessionStorageService: SessionStorageService,
    public _service: HttpService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.isServer = isPlatformServer(this.platformId);
    this._service.showFooter.subscribe((x) => {
      if (x != null) {
        this.footer = x;
    
      }
    });
  }

navList(type:string){
this.route?.navigate([`${type}/list`])

}


}
