<section class="searchBnr ">
  <div class="container height_100per">
    <div>
      <div>
        <h2 class="textAlign_center font_Size32 font_Weight600  infiniteNightText">Search</h2>
        <div class="margin_Bottom24 textAlign_center">
          <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/phaserBeamLinebottom.svg"
            alt="phaserBeamLinebottom" />
        </div>
      </div>
    </div>
  </div>
</section>



<section class="search_show">
  <div class="container">
    <div class="row margin_Bottom50">
      <div class="col-lg-9 MobSpace_mb24">
        <h2 *ngIf="websiteList.length || blogList.length" class="font_Size24 font_Weight500 infiniteNightText">Search results for <span class="phaserBeamText">“
            {{param1Value}} ” </span></h2>
            <h2 *ngIf="!websiteList.length && !blogList.length" class="font_Size24 font_Weight500 infiniteNightText">Search results for <span class="phaserBeamText">“
              {{param1Value}} ” </span></h2>
              <h2 *ngIf="!websiteList.length && !blogList.length" class="font_Size24 font_Weight500 infiniteNightText">No Result Found</h2>
      </div>
      <div class="col-lg-3">
        <div class="position_Relative">
          <div class='searchinputGroup'>
            <span class='searchinputGroupText'>

              <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/iconamoon_search.svg"
                alt="iconamoon_search" />

            </span>
            <input placeholder="Search"   (input)="onSearchInput($event)"  id="search-input">


          </div>


          <ul *ngIf="currentSearchTerm"  class="smallSearchFrameGroup">
            <div  *ngFor="let result of searchResults">
              <li (click)="goToSearch(result)" class="listSearchDiv">{{ result?.title }} </li>
            </div>
            <!-- <span class="infiniteNightText"> Hospitality </span>  -->

                          </ul>
        </div>
      </div>
    </div>


    <ul *ngFor="let websites of websiteList" class="searchResultsGroup">
      <li>
        <span>{{websites?.title}} </span>
        <img  (click)="nav(websites?.slug)"
          src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/page_link.svg" alt="page_link" />

      </li>


    </ul>

    <div class="extraordinary_abundance_bottomborder margin_Bottom24  padding_Bottom16">
    </div>

    <!--  -->



    <div *ngFor="let blog of blogList" class="newsroomItems">
      <div class="row">
        <div *ngIf="blog?.short_image" class="col-md-4">
          <a  class="newsroomItem_img">
            <img [src]="blog?.short_image "
              alt="PLI_RGCACardies" />
          </a>
        </div>
        <div class="col-md-8">
          <a 
            class="hrefLinkHover font_Size20 font_Weight600 infiniteNightText margin_Bottom12 Display_Block">
            {{blog?.title}}
          </a>
          <p class="font_Size16 font_Weight400 steelWoolText margin_Bottom34">
{{blog?.short_content}}
            |<span class="blogdate">
              {{formatDate(blog?.updated_at)}}
            </span>
          </p>

          <a (click)="navBlog(blog?.category,blog)" class="view_btn">
            <span>View</span>
            <img class="width_18px"
              src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/infiniteNightRight_arrow.svg"
              alt="infiniteNightRight_arrow" />
          </a>
        </div>
      </div>


    </div>



  </div>
</section>