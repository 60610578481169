import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DynamicPageComponent } from './dynamic-page/dynamic-page.component';
import {BlogListComponent  } from './blog/blog-list/blog-list.component';
import {BlogDetailComponent  } from './blog/blog-detail/blog-detail.component';
import {NewsListComponent  } from './news/news-list/news-list.component';
import {NewsDetailComponent  } from './news/news-detail/news-detail.component';
import {SearchComponent} from './search/search.component'


const routes: Routes = [

  {
    path: 'search/detail',
    component: SearchComponent,
    data: {
      queryParams: {
        param1: 'search'
       
      },
    },
  },
  {
    path: 'card-talk/:id',
    component: BlogDetailComponent
  },
  // https://www.plicards.com/card-talk/pli-introduces-100-plastic-free-key-cards
  {
    path: 'news/detail/:id',
    component: NewsDetailComponent
  },
  {
    path: 'blog/list',
    component: BlogListComponent
  },
  {
    path: 'news/list',
    component: NewsListComponent
  },
 {
    path: '**',
    component: DynamicPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
