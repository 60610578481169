import { Component, Inject, PLATFORM_ID, makeStateKey,TransferState } from '@angular/core';
import { HttpService } from '../../common_service/http.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ApiService } from '../shared/data-service.service';
import { SessionStorageService } from '../../session-service.service';
import { isPlatformBrowser } from '@angular/common';
import { Title} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
declare var $: any;
const MY_KEY = makeStateKey<string>('myKey');
@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent {
  CompanyID: any = '';
  Url: string = environment.sustainability_Pdf;
  blogId: number = 0;
  blogDetail: any = {};
  recentBlogList: any = [];
  defaultIMG: string = environment.defaultImg;
  blogContent: string = '';
  newsList: any = [];
  fb_url: string = '';
  twitter_url: string = '';
  mail_url: string = '';
  linked_url: string = '';
  footer = false;
  constructor(
    private route: ActivatedRoute,
    public service: HttpService,
    private _router: Router,
    private apiService: ApiService,
    private sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    public _service: HttpService,
    private transferState :TransferState

  ) {
    this._service.showFooter.subscribe((x) => {
      if (x != null) {
        this.footer = x;
      }
    });
  }

  ngOnInit() {

  
    this.route.params.subscribe(params => {
      this.blogId = params['id'];
      this.CompanyID = this.sessionStorageService.getItem("company_id");
      
if(this.sessionStorageService?.menu?.length&&this.newsList?.length&&this.recentBlogList?.length){
  this.getData()
}else{
  this.apiService.getMenu()?.subscribe(
    (response)=>{
      this.transferState.set(MY_KEY, response?.company_id);
      this.getData()
    },(error) => {
      // Handle error if needed
      console.error('Error fetching menu', error);
    }
   );
}
    });
  }

  getData(){
    // if(this.CompanyID === '' || this.CompanyID === undefined || this.CompanyID === null){
    //   this.CompanyID = 
    // }else{
    // }
    this.CompanyID = this.transferState.get(MY_KEY, '') === '' ? this.sessionStorageService.getItem("company_id") : this.transferState.get(MY_KEY, '')
    console.log('CompanyID::: ', this.CompanyID);
    // this.CompanyID = environment.companyId;

    let menu = this.sessionStorageService.menu

    if(this.CompanyID && menu?.length){
    
      this._service.hideAndShoeFooter(true);
      this.apiService.getBlogDetail(this.blogId, this.CompanyID).subscribe({
        next: (data: any) => {
          this.blogDetail = data;
          this.blogContent = JSON?.parse(data?.page?.page_data)?.[0]?.template_html || "";
          this.titleService.setTitle(`${this.blogDetail?.blog_name} - PLI`)
          this.fb_url =`https://facebook.com/sharer/sharer.php?u=${environment.Site_url}card-talk/${this.blogDetail?.slug}`
          this.twitter_url =`https://twitter.com/intent/tweet/?text=${this.blogDetail?.blog_name};url=${environment.Site_url}card-talk/${this.blogDetail?.slug}`
          this.mail_url =`mailto:?subject=${this.blogDetail?.blog_name};body=${environment.Site_url}card-talk/${this.blogDetail?.slug}`
          this.linked_url =`https://www.linkedin.com/shareArticle?mini=true&amp;url=${environment.Site_url}card-talk/${this.blogDetail?.slug};title=${this.blogDetail?.blog_name};source=${environment.Site_url}card-talk/${this.blogDetail?.slug}`
          this.service.updateOgTitle(`${this.blogDetail?.blog_name} - PLI`);
          this.service.updateTwitterTitle(`${this.blogDetail?.blog_name} - PLI`);
          this._service.updateDescription(this.blogDetail?.page?.short_content ?? "");
          this._service.updateOgUrl();
          this._service.updateOgDescription(this.blogDetail?.page?.short_content ?? "");
          this._service.updateOgImage(this.blogDetail?.page?.short_image, true);
          this._service.updateTwitterCard("summary_large_image");
          this._service.updateTwitterUrl();
          this._service.updateTwitterDescription(this.blogDetail?.page?.short_content ?? "");
          this._service.updateTwitterImage(this.blogDetail?.page?.short_image, true);
        },
        error: (error) => {
          console.log("error", error)
        }
      });

      if(this.newsList?.length === 0){
        this.apiService.getAllNews(this.CompanyID, true).subscribe({
          next: (data: any) => {
            this.newsList = data?.news || [];
            setTimeout(() => this.slider(), 1500)
          },
          error: (error) => {
            console.log("error", error)
          }
        });
      }
    
     
if( this.recentBlogList?.length === 0){
  this.apiService.getAllBlogs(this.CompanyID, true).subscribe({
    next: (data: any) => {
      this.recentBlogList = data?.blogs || [];
   

    },
    error: (error) => {
      console.log("error", error)
    }
  });
}
     

     

    }
  }


  navigate() {
    this._router.navigate([`blog/list`])
  }
  navDetail(id: any) {

    this.apiService.setBlogId(id);
  }

  formatDate(dateString: Date) {

if(!dateString){
return null
}

    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };


  slider() {
    if (isPlatformBrowser(this.platformId)) {
      $('.blog_owl').owlCarousel({
        navText: ['<img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/left_arrow.svg">', '<img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/righarrow_icon.svg">'],
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        center: false,
        autoplay: true,
        autoplayHoverPause: true,
        autoplaySpeed: 5000,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 4
          }
        }
      })

      // counter number
      $('.counter_number').each(() => {
        $(this).prop('Counter', 0).animate({
          Counter: $(this).text()
        }, {
          duration: 5000,
          easing: 'swing',
          step: (now: any) => {
            $(this).text(Math.ceil(now));
          }
        });
      });


    }
  }

  navigateBlogDetail(id: any) {
    this.apiService.setBlogId(id);
    // this.ngOnInit();
  }


 


  navNewsDetail(news: any) {

    if (news?.news_id) {
      if(news?.category==='BLOG'){
        this._router.navigate([`card-talk/${news?.slug}`])
      }else{
        this._router.navigate([`news/detail/${news?.news_id}`])
      }
      
            
          }

   
  }
  navNewslist() {
    this?._router?.navigate(['news/list'])

  }


}
